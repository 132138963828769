import React, {FC} from 'react';

import {Container} from 'components';

import './AboutUsEn.scss';

const AboutUsEn: FC = () => {
  return (
    <Container className="TermsOfUse" maxWidth={720}>
      <div className="TermsOfUse__main-text" />
      <h1 className="TermsOfUse__heading">关于我们</h1>
      <section className="TermsOfUse__section">
        <p className="TermsOfUse__text">
          <b>
            象寄科技，专注于将AIGC(AI Generated
            Content)生成能力在商业视觉内容场景中落地应用。象寄科技成立于2022年7月，创始团队来自复旦大学计算机学院，同时与复旦大学类脑智能科学与技术研究院的AI产学研团队深度合作。始终致力于为国内外电商卖家、DCT品牌独立站、营销团队打造便捷、优质的视觉内容生产平台。2023年初，象寄科技融合多模态AI技术，针对电商和营销场景的垂类数据，构建行业视觉内容生成模型。
          </b>
          <br />
        </p>

        <p className="TermsOfUse__text">公司名：苏州象寄科技服务有限公司</p>
        <p className="TermsOfUse__text">地址: 上海市浦东新区纳贤路800号A座228室</p>

        <p className="TermsOfUse__text">电话号码: +8617664075341</p>
        <p className="TermsOfUse__text">支持邮箱: bussiness@tosoiot.com</p>
      </section>

      <div className="TermsOfUse__main-text" />
      <h1 className="TermsOfUse__heading">我们的产品</h1>
      <section className="TermsOfUse__section">
        <p className="TermsOfUse__text">
          AI生图工具：面向电商和营销人员的专业AI制作工具，生成式AI驱动的电商和营销SaaS系统。它可以智能识别图片主体，生成融合背景，智能混搭图形生成海报，还可以根据图片和描述生成爆款营销文案等等。
        </p>
        <p className="TermsOfUse__text">
          视频翻译工具：从视频中删除硬字幕，并将其替换为翻译语言的字幕。支持多种流行背景音乐和人声旁白合成
        </p>
        <p className="TermsOfUse__text">
          图片翻译工具：支持网页版图片批量翻译和API调用，图片、文字水印轻松配置，方便使用的交互式微调工具，拖拽制作精美素材
        </p>
      </section>

      {/* <h1 className="TermsOfUse__heading">About us</h1>

      <div className="TermsOfUse__main-text" />
      <section className="TermsOfUse__section">
        <p className="TermsOfUse__text">
          AI tools: A professional AI production tool for e-commerce and marketing people, not artists Generative
          AI-driven e-commerce and marketing SaaS system. It can intelligently recognize the main body of the picture,
          generate fused backgrounds, intelligently mix and match graphics to generate posters, and also generate
          explosive marketing copy based on pictures and descriptions, and so on.
        </p>
        <p className="TermsOfUse__text">
          Video Translation Tool: Remove hard subtitles from videos and replace them with subtitles in the translated
          language. Support many popular background music and vocal narration synthesis
        </p>
        <p className="TermsOfUse__text">
          Picture translation tool: support web version of the picture batch translation and API call, picture, text
          watermark easy configuration, easy to use interactive fine-tuning tools, drag and drop to make beautiful
          material (picture + video)
        </p>
      </section> */}
    </Container>
  );
};

export default AboutUsEn;
