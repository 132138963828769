import React, {FC} from 'react';

import {useWindowDimensions} from 'hooks';
import * as S from './Styles';
import ValuesIllustrationPlaceholder from './ValuesIllustrationPlaceholder.webp';
import ValuesIllustration from './service.jpg';

const AboutUsValue: FC = () => {
  const {width} = useWindowDimensions();

  let iconSize;
  if (width < 600) {
    iconSize = {height: 188, width: 235};
  } else if (width < 768) {
    iconSize = {height: 211, width: 288};
  } else {
    iconSize = {height: 376, width: 456};
  }
  return (
    <S.Container>
      <S.Wrapper>
        <S.Image
          alt=""
          height={iconSize.height}
          placeholderSrc={ValuesIllustrationPlaceholder}
          realSrc={ValuesIllustration}
          width={iconSize.width}
        />
        <S.RightContainer>
          <S.Title>客服：象寄小象</S.Title>
          <S.Subtitle>
            您也可以直接联系我们的客服：象寄小象 <br />
          </S.Subtitle>
          <S.Subtitle>
            {' '}
            微信号：<b>xiangjifanyi</b>
          </S.Subtitle>
          <S.Subtitle>
            {' '}
            电话号码：<b>17664075341</b>
          </S.Subtitle>
          <S.Subtitle>
            {' '}
            支持邮箱：<b>bussiness@tosoiot.com</b>
          </S.Subtitle>
          <S.Subtitle>
            {' '}
            公司名：<b>苏州象寄科技服务有限公司</b>
          </S.Subtitle>
          <S.Subtitle>
            {' '}
            公司地址：<b>上海市浦东新区纳贤路800号A座228室</b>
          </S.Subtitle>
        </S.RightContainer>
      </S.Wrapper>
    </S.Container>
  );
};

export default AboutUsValue;
